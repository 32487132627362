<script setup>

const { leasingDrawerOpened } = useLeasingParams()
const route = useRoute();
const refFinancing = ref();
const slots = useSlots();

const props = defineProps({
  opened: Boolean,
  noButtonCall: Boolean,
  customLogo: Boolean,
  isAbsolute: Boolean,
  noLink: Boolean,
})

const back = () => {
  if (route.name === 'cars') {
    navigateTo({name: 'model'});
  }
}

const isDigitalOrder = computed(()=> {
  return route.name === 'transaction-form';
})

const btnTxt = computed(()=> {
  return leasingDrawerOpened.value ?  'Zwiń' : 'Dopasuj ratę';
})
</script>

<template>
  <div :class="isAbsolute ? 'absolute': 'fixed'" class="top-0 bg-white shadow-box print:shadow-none print:border-b print:border-b-gray-300 z-50 w-full">
    <Container no-paddings>
      <AppHeaderActionBar :no-link="noLink" no-button-call :custom-logo="customLogo">
        <template #custom-logo v-if="customLogo">
          <slot name="custom-logo-html"></slot>
        </template>
        <div class="flex gap-6 items-center">
          <template v-if="!slots.button && !isDigitalOrder">
          <ButtonToggle class="text-sm font-light text-azure !fill-azure"
                        v-model:toggled="leasingDrawerOpened"
                        v-if="!route.meta?.hideFinancingButton"
                        with-chevron>
            {{btnTxt}}
          </ButtonToggle>
          </template>
          <template v-else>
            <slot name="button" />
          </template>
          <ButtonCall v-if="!noButtonCall" />
        </div>
      </AppHeaderActionBar>
      <Accordion ref="refFinancing" :expanded="!!leasingDrawerOpened || opened">

          <slot />

      </Accordion>
    </Container>
  </div>
  <div class="h-12 md:h-16"></div>
</template>
